<template>
  <section class="marketplace-homepage">
    <carousel 
      :autoplay="true"
      :navigationEnabled="false"
      :perPage="1"
      :centerMode="true"
      :loop="true"
      :autoplayTimeout="10000"
      paginationColor="#fff"
      paginationPosition="bottom-overlay"
      class="marketplace-homepage__carousel"
    >
      <slide v-if="loading_slides">
        <div class="marketplace-homepage__carousel-loader">
          <Loader />
        </div>
      </slide>
      <slide
        v-else
        v-for="({ thumb_src, alt_text, src, route, id }) in homepage_slides"
        :key="id"
      >
        <router-link :to="route" itemprop="link">
          <v-lazy-image :src="is_mobile_device ? (thumb_src || src) : src" :src-placeholder="thumb_src || default_image_src" :alt="alt_text" />
        </router-link>
      </slide>
    </carousel>

    <h2 class="marketplace-title marketplace-homepage__title">{{ $translate("homepage.popular_products") }}</h2>

    <prouduct_slider
      :products="get_popular_products"
      :load_right_away="true"
      :loading="loading_popular_products"
    />

    <router-link :to="`/products/${POPULAR_PRODUCTS}`">
      <div class="marketplace-button marketplace-button--basic">{{ $translate("homepage.show_more_popular") }}</div>
    </router-link>

    <collection_banners :marketplace_collection_banners="collections" :loading="loading_collections"/>

    <h2
      class="marketplace-title marketplace-homepage__title marketplace-homepage__title--larger-margin"
    >{{ $translate("homepage.favorite_products") }}</h2>

    <highlighted_products :imported_products="favorite_products" :loading="loading_favorite_products"/>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import { UPDATE_POPULAR_PRODUCTS } from "../../../Admin/stores/Admin/content/constants";
import { Carousel, Slide } from "../../../Shared/components/slider/index"
import { default_image_src, SHARED_STORE } from "../../../Shared/constants/other"
import { get_marketplace_homepage_collections, get_marketplace_homepage_favorite_products, get_marketplace_homepage_slides, get_popular_marketplace_products } from "../../constants/endpoints";
import { MARKETPLACE_PRODUCTS_STORE, POPULAR_PRODUCTS } from "../../constants/other";

const prouduct_slider = () => import("../../../Shared/components/utils/product_slider")
const collection_banners = () => import("../../../Client/components/utils/home/collection_banners")
const highlighted_products = () => import("../../../Client/components/utils/home/highlighted_products")

export default {
  components: {
    Carousel,
    Slide,
    prouduct_slider,
    collection_banners,
    highlighted_products,
    Loader,
    VLazyImage
  },
  data() {
    return {
      initilal_popular_products: [],
      homepage_slides: [],
      collections: [],
      favorite_products: [],
      loading_popular_products: true,
      loading_favorite_products: true,
      loading_collections: true,
      loading_slides: true,
      default_image_src,
      POPULAR_PRODUCTS
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device"
    ]),
    ...mapState(MARKETPLACE_PRODUCTS_STORE, {
      loaded_popular_products: "popular_products"
    }),
    get_popular_products() {
      const products = this.loaded_popular_products.slice(0, 4)

      return products
    }
  },
  mounted() {
    get_marketplace_homepage_slides().then(({ data }) => {
      this.homepage_slides = data

      this.loading_slides = false
    })
    get_popular_marketplace_products().then(({ data }) => {
      this.update_popular_products(data)

      this.loading_popular_products = false
    })
    get_marketplace_homepage_collections().then(({ data }) => {
      this.collections = data

      this.loading_collections = false
    })
    this.get_favorite_products()
  },
  methods: {
    ...mapMutations(MARKETPLACE_PRODUCTS_STORE, {
      update_popular_products: UPDATE_POPULAR_PRODUCTS
    }),
    async get_favorite_products() {
      const { data } = await get_marketplace_homepage_favorite_products()

      this.favorite_products = data.filter(val => val)
      this.loading_favorite_products = false
    }
  }
};
</script>

<style lang="scss" scoped>
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;
@use "../../../../styles/marketplace" as *;

.marketplace-homepage {
  padding: $spacing--medium 0;
  text-align: center;

  &__title {
    margin: 0 auto $spacing--medium;
    text-transform: uppercase;

    &--larger-margin {
      margin: $spacing--extra-large auto $spacing--medium;
    }
  }

  &__carousel {
    $carousel-height: 400px;

    position: relative;
    margin-bottom: $spacing--extra-large + $spacing--medium;
    height: $carousel-height;

    &:before {
      position: absolute;
      content: "";
      top: -#{$spacing--medium};
      bottom: -#{$spacing--medium};
      left: calc(50% - 50vw);
      width: 100vw;
      height: calc(#{$carousel-height} + 2 * #{$spacing--medium});
      background-color: $grey--lightest;
    }

    &-loader {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
    }

    img {
      display: block;
      height: $carousel-height;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
    }

    @media (max-width: $tablet) {
      $carousel-height: 300px;

      height: $carousel-height;

      img {
        height: $carousel-height;
      }

      &:before {
        height: calc(#{$carousel-height} + 2 * #{$spacing--medium});
      }
    }
  }
}
</style>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;

  .marketplace-homepage {
    .product-slider__background {
      border: 1px solid $grey--light !important;
    }

    .product-collection-banner{
      &__gradient {
        background: linear-gradient(314.09deg, $brand-color--dark 0%, $blue 100%) !important;
      }

      &__item {
        text-align: left;
      }
    }

    .highlighted-products__product {
      border: 1px solid $grey--light !important;
    }
  }
</style>
